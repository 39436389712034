/*App*/

html,
body,
#root,
.container-fluid,
.App {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

html {
    overflow: hidden;
}

.App {
    font-family: Roboto, Arial, Sans-serif;
    display: flex;
}

.Page-view {
    flex: 1;
    overflow-y: auto;
}

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

.fab-button {
    position: absolute !important;
    bottom: 16px !important;
    right: 25px !important;
}

.max-width {
    width: 100%;
}

.max-height {
    height: 100%;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.relative {
    position: relative;
}

.paper-modal {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
        0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    padding: 32px;
    left: 0px;
    right: 0px;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
    transition: opacity 0.2s ease-in-out;
}

.paper-transition {
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 195ms;
    will-change: transform;
    transform: scale(1);
}

.display-none {
    left: auto !important;
}

input[type='time']::-webkit-inner-spin-button {
    opacity: 1;
}

input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
}

input[type='time']::-webkit-clear-button {
    opacity: 1;
}

.MuiPopover-root {
    z-index: 1600 !important;
}

/* date pickers */

.MuiPickersCalendarHeader-dayLabel,
.DayPicker-Weekday {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold !important;
}

/* placeholders */
input::placeholder,
textarea::placeholder {
    opacity: 1 !important;
    color: #333 !important;
    font-size: 12px !important;
    font-family: Roboto, Arial, Sans-serif !important;
}

em {
    color: #333 !important;
}

b,
strong {
    font-weight: 500 !important;
}

.rrt-title {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}

.rrt-text {
    font-size: 14px;
}

.jodit-workplace > div > * {
    margin: 0;
}
