.change-order-container {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -52px;
    gap: 5px;
    padding: 4px;
    padding-right: 12px !important;
}
.change-order-container button {
    z-index: 10;
    padding: 8px;
}
.radio-form-item > div:nth-of-type(2) {
    max-width: 88px;
    width: 100%;
    flex: 1;
    padding-right: 0;
}
.radio-form-item > div:nth-of-type(3) {
    padding-left: 0;
    min-width: 94px;
}
.radio-form-item-2-fields > div:nth-of-type(1) {
    max-width: 85px;
}
