body {
    margin: 0;
    background: #eeeeee;
}

@font-face {
    font-family: 'Gilmer Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Regular'), url('../assets/fonts/gilmer-regular.woff') format('woff');
}

@font-face {
    font-family: 'Gilmer Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Bold'), url('../assets/fonts/gilmer-bold.woff') format('woff');
}

@font-face {
    font-family: 'Gilmer Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Heavy'), url('../assets/fonts/gilmer-heavy.woff') format('woff');
}

@font-face {
    font-family: 'Gilmer Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Light'), url('../assets/fonts/gilmer-light.woff') format('woff');
}

@font-face {
    font-family: 'Gilmer Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Outline'), url('../assets/fonts/gilmer-outline.woff') format('woff');
}

@font-face {
    font-family: 'Gilmer Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Medium'), url('../assets/fonts/gilmer-medium.woff') format('woff');
}

html,
body,
#root,
.content-flex {
    height: 100%;
    font-family: 'Roboto, Arial, Sans-serif' !important;
}

.content-flex {
    display: flex;
    overflow: hidden;
}

.content-width {
    width: 100%;
    overflow-y: auto;
}

input::placeholder,
textarea::placeholder {
    opacity: 1 !important;
    color: #333 !important;
    font-size: 12px !important;
    font-family: 'Roboto, Arial, Sans-serif' !important;
}

em {
    color: #333 !important;
}

.header-link {
    line-height: 50px;
    padding: 0 5px;
    text-decoration: none;
    color: white;
    margin-left: 10px;
}

.header-link:hover {
    color: #565a65;
    background-color: white;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: absolute;
    left: 50%;
    transition: height 0.5s;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.rrt-title {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}

.rrt-text {
    font-size: 14px;
}

.jodit-ui-block__className {
    display: none;
}

.jodit-workplace {
    font-family: 'Roboto', 'Arial', 'sans-serif';
}

/*
    ? MUI PLACEHOLDER
*/
.usePlaceholderTextField input::-webkit-input-placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
    color: rgba(0, 0, 0, 0.38) !important;
}

.usePlaceholderTextField input:-ms-input-placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
    color: rgba(0, 0, 0, 0.38) !important;
}

.usePlaceholderTextField input::placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
    color: rgba(0, 0, 0, 0.38) !important;
}

.usePlaceholder::-webkit-input-placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
    color: rgba(0, 0, 0, 0.38) !important;
}

.usePlaceholder:-ms-input-placeholder {
    font-size: 18px !important;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
    color: rgba(0, 0, 0, 0.38) !important;
}

.usePlaceholder::placeholder {
    font-size: 18px !important;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
    color: rgba(0, 0, 0, 0.38) !important;
}

.usePlaceholderTextField input {
    height: inherit;
    box-sizing: border-box;
}

/*
    ? LOCATION SELECTOR
*/

.locationPlaceholder input::-webkit-input-placeholder {
    font-size: 14px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
    color: rgba(0, 0, 0, 1) !important;
}

.locationPlaceholder input:-ms-input-placeholder {
    font-size: 14px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
    color: rgba(0, 0, 0, 1) !important;
}

.locationPlaceholder input::placeholder {
    font-size: 14px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
    color: rgba(0, 0, 0, 1) !important;
}

.locationPlaceholder::-webkit-input-placeholder {
    font-size: 14px;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
    color: rgba(0, 0, 0, 1) !important;
}

.locationPlaceholder:-ms-input-placeholder {
    font-size: 14px !important;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
    color: rgba(0, 0, 0, 1) !important;
}

.locationPlaceholder::placeholder {
    font-size: 14px !important;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
    color: rgba(0, 0, 0, 1) !important;
}

.MuiFormHelperText-root.Mui-error {
    width: 250px;
}

.MuiTooltip-popper {
    z-index: 9999999 !important;
}
